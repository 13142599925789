import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { Switch } from '@headlessui/react';
import request from 'request';
import { InsuranceProvidersType, UserInfoFormType } from 'types';
import { useInsuranceProviders } from 'api';
import { Select } from '../components/Select';
import simpleDoctor from 'img/simple-doctor.svg';

import {
  PenIcon,
  CloseBurgerIcon,
  SettingsIcon,
  UserIcon,
  AdditionIcon,
  CaretRightIcon,
  LockIcon,
} from 'components/svgs';
import { AxiosError } from 'axios';
import { useAuth } from 'auth/AuthProvider';
import useStoreServices from 'storeServices';
import { isEmpty } from 'lodash';
import { luhn_check } from '../helpers';
import { CustomFormikPhoneInput } from '../components/InternationalPhoneNumber';
import { isValidPhoneNumber } from 'react-phone-number-input';
import ChangePasswordForm from '../components/ChangePasswordForm';

export default function Account() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isUserDataEditing, setIsUserDataEditing] = useState(false);
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] =
    useState(false);
  const [smsNotificationsEnabled, setSmsNotificationsEnabled] = useState(false);
  const [tosEnabled, setTosEnabled] = useState(false);
  const [isSettingsEnabled, setIsSettingsEnabled] = useState(false);
  const [isChangePasswordEnabled, setIsChangePasswordEnabled] = useState(false);
  const [insurance, setInsuranceProvider] = useState<any>();
  const [errorMsg, setErrorMsg] = useState('');
  const [isEmailChanged, setIsEmailChanged] = useState(false);

  const { availableInsuranceProviders, setAvailableInsuranceProviders } =
    useStoreServices();
  const { data: insuranceProviders, isFetching } = useInsuranceProviders();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Εισάγετε το όνομα σας'),
    last_name: Yup.string().required('Εισάγετε το επώνυμο σας'),
    email: Yup.string()
      .email('Εισάγετε μια έγκυρη διεύθυνση email')
      .required('Εισάγετε το email σας'),
    phone_number: Yup.string()
      .required('Εισάγετε τον αριθμό σας')
      .test('phone', 'Εισάγετε έγκυρο αριθμό', (value) => {
        return isValidPhoneNumber(value);
      }),
    insurance_provider: Yup.object()
      .shape({ name: Yup.string(), id: Yup.number() })
      .required('Εισάγετε το ασφαλιστικό σας ταμείο'),
    ssrn: Yup.string()
      .required('Εισάγετε τον αριθμό μητρώου σας')
      .when('has_foreign_ssrn', {
        is: false,
        then: (schema) =>
          schema
            .matches(/^\d+$/, 'Εισάγετε μόνο αριθμούς')
            .max(11, 'Ο ΑΜΚΑ πρέπει να είναι 11 αριθμοί')
            .min(11, 'Ο ΑΜΚΑ πρέπει να είναι 11 αριθμοί')
            .test('ssrn', 'Μη έγκυρος ΑΜΚΑ', luhn_check),
      }),
  });

  const fetchUserData = async () => {
    try {
      const { data } = await request.get('/api/v1/auth/me/');
      setEmailNotificationsEnabled(data.email_notifications_accepted);
      setTosEnabled(data.tos_accepted);
      const localInsurance = availableInsuranceProviders?.find(
        (item) => data.insurance_provider === item.id,
      );
      setInsuranceProvider(localInsurance);
      setSmsNotificationsEnabled(data.sms_notifications_accepted);
      return data;
    } catch (error) {
      console.log(error);
      localStorage.removeItem('token');
      logout();
      navigate('/login');
      throw error;
    }
  };
  const { data } = useQuery(['registeredUserData'], fetchUserData, {
    staleTime: 0,
    enabled: !isEmpty(availableInsuranceProviders),
  });

  const handleSubmitEditedUserData = async (
    data: UserInfoFormType,
    { setErrors }: FormikHelpers<UserInfoFormType>,
  ) => {
    await request
      .patch('/api/v1/auth/me/', {
        ...data,
        insurance_provider: data.insurance_provider?.id,
      })
      .then((res) => {
        if (res.status === 200) {
          if (isEmailChanged) {
            setErrorMsg(
              'Το email σου άλλαξε. Παρακαλώ επιβεβαίωσε το νέο σου email μέσω του link που σου έχουμε στείλει.',
            );
            localStorage.setItem('userEmailChanged', 'true');
            setTimeout(() => {
              setErrorMsg('');
              localStorage.removeItem('userEmailChanged');
              logout();
            }, 5000);
          }
        }
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 400) {
          setErrors(error.response.data!);
        } else {
          setErrorMsg('Ούπς! Κατι πηγε στραβά! Παρακαλώ προσπαθήστε αργοτερα!');
          console.log(error);
        }
        setIsUserDataEditing(true);
      });
  };

  useEffect(() => {
    !isFetching &&
      !isEmpty(insuranceProviders) &&
      isEmpty(availableInsuranceProviders) &&
      setAvailableInsuranceProviders(
        insuranceProviders as InsuranceProvidersType[],
      );
  });

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-6xl rounded-3xl bg-gray-50 px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <h1 className="mb-20 text-center text-2xl font-bold md:text-3xl lg:text-4xl">
          Ο λογαριασμός μου
        </h1>
        <Formik
          enableReinitialize={true}
          initialValues={{
            first_name: data?.first_name ?? '',
            last_name: data?.last_name ?? '',
            phone_number: data?.phone_number ?? '',
            email: data?.email ?? '',
            has_foreign_ssrn: data?.has_foreign_ssrn ?? false,
            ssrn: data?.ssrn ?? '',
            insurance_provider: insurance ?? null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmitEditedUserData}
        >
          {({ isSubmitting, setFieldValue, errors }) => (
            <Form>
              <div className="mx-auto mb-6 grid max-w-md grid-cols-1 gap-5 lg:max-w-5xl lg:grid-cols-2">
                <div className="flex max-w-lg flex-col justify-start rounded-3xl bg-white px-4 py-8 text-left xl:px-14 2xl:px-20">
                  <span className="text-lg font-bold sm:text-xl">ΟΝΟΜΑ</span>
                  <Field
                    type="first_name"
                    name="first_name"
                    id="first_name"
                    data-test="account-first_name-field"
                    className={
                      !isUserDataEditing
                        ? 'user-area-input'
                        : 'user-area-input-active'
                    }
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="account-first_name-error"
                  />
                  <span className="text-lg font-bold sm:text-xl">ΕΠΩΝΥΜΟ</span>
                  <Field
                    type="last_name"
                    name="last_name"
                    id="last_name"
                    data-test="account-last_name-field"
                    className={
                      !isUserDataEditing
                        ? 'user-area-input'
                        : 'user-area-input-active'
                    }
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="account-last_name-error"
                  />
                  <span className="text-lg font-bold sm:text-xl">
                    ΤΗΛΕΦΩΝΟ ΕΠΙΚΟΙΝΩΝΙΑΣ
                  </span>
                  {isUserDataEditing ? (
                    <CustomFormikPhoneInput name="phone_number" />
                  ) : (
                    <Field
                      type="phone_number"
                      name="phone_number"
                      id="phone_number"
                      data-test="account-phone_number-field"
                      className="user-area-input"
                    />
                  )}
                  <ErrorMessage
                    name="phone_number"
                    component="div"
                    className="text-xs text-red-600"
                    data-test="account-phone_number-error"
                  />
                  <span className="text-lg font-bold sm:text-xl">
                    EMAIL ΕΠΙΚΟΙΝΩΝΙΑΣ
                  </span>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    data-test="account-email-field"
                    className={
                      !isUserDataEditing
                        ? 'user-area-input border-none  p-0'
                        : 'user-area-input-active'
                    }
                    onChange={(e: any) => {
                      setFieldValue('email', e.target.value);
                      setIsEmailChanged(e.target.value !== data.email);
                    }}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className=" text-xs text-red-600"
                    data-test="account-email-error"
                  />
                  {isEmailChanged && (
                    <div
                      className="mt-2 text-sm text-red-700"
                      data-test="email-changed-message"
                    >
                      Προσοχή! Σε περίπτωση που αλλάξεις το email σου, θα
                      αποσυνδεθείς αυτόματα από την εφαρμογή και δε θα μπορείς
                      να συνδεθείς ξανά με το παλιό σου email. Για να συνδεθείς
                      ξανά πρέπει να επιβεβαιώσεις το νέο σου email μέσω του
                      link επιβεβαίωσης που σου έχουμε αποστείλει. Εφόσον το
                      επιβεβαιώσεις, μπορείς να συνδεθείς με αυτό.
                    </div>
                  )}
                </div>

                <div className="flex max-w-lg flex-col justify-start rounded-3xl bg-white px-4 py-8 text-left xl:px-14 2xl:px-20">
                  <span className="text-lg font-bold sm:text-xl">ΑΣΦΑΛΕΙΑ</span>
                  {isUserDataEditing ? (
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <Field
                        as={Select}
                        name="insurance_provider"
                        type="insurance_provider"
                        serviceGrp={insuranceProviders || []}
                        servicesIcon={simpleDoctor}
                        value={insurance}
                        handleOnChange={(item: any) => {
                          setInsuranceProvider(item);
                          setFieldValue('insurance_provider', item);
                        }}
                        data-test="account-insurance_provider-field"
                        className={
                          !isUserDataEditing
                            ? 'user-area-input'
                            : 'user-area-input-active'
                        }
                      />
                    </div>
                  ) : (
                    <input
                      name="insurance_provider"
                      type="insurance_provider"
                      data-test="account-insurance_provider-field"
                      className="user-area-input"
                      readOnly
                      value={insurance?.name || ''}
                    />
                  )}
                  <ErrorMessage
                    name="insurance_provider"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="account-insurance_provider-error"
                  />
                  <span className="text-lg font-bold sm:text-xl">ΑΜΚΑ</span>
                  {isUserDataEditing && (
                    <div className="flex">
                      <div className="flex h-5 items-start lg:items-center">
                        <Field
                          data-test="register-has_foreign_ssrn"
                          name="has_foreign_ssrn"
                          type="checkbox"
                          className="focus:ring-3 h-4 w-4 rounded border border-gray-400 bg-white focus:ring-blue-500"
                        />
                      </div>
                      <label
                        htmlFor="has_foreign_ssrn"
                        className="ml-2 text-sm text-gray-900"
                      >
                        Έχω αλλοδαπό ΑΜΚΑ
                      </label>
                    </div>
                  )}
                  <Field
                    name="ssrn"
                    type="string"
                    data-test="account-ssrn-field"
                    className={
                      !isUserDataEditing
                        ? 'user-area-input'
                        : 'user-area-input-active'
                    }
                  />
                  <ErrorMessage
                    name="ssrn"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="account-ssrn-error"
                  />
                </div>
                <button
                  type={isUserDataEditing ? 'button' : 'submit'}
                  disabled={
                    isSubmitting ||
                    localStorage.getItem('userEmailChanged') === 'true'
                  }
                  onClick={() => {
                    Object.keys(errors).length < 1 &&
                      setIsUserDataEditing((prev) => !prev);
                  }}
                  className="inline-flex max-w-[198px] items-center justify-self-center rounded-full border border-primary bg-gray-50 px-6 py-4 text-right text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 lg:col-start-2 lg:col-end-2 lg:justify-self-end"
                  data-test="account-edit-save-button"
                >
                  <span>
                    {isUserDataEditing ? 'Αποθήκευση' : 'Επεξεργασία'}
                  </span>
                  <PenIcon className="ml-2.5 h-5 w-5" />
                </button>
                {errorMsg && (
                  <p data-test="error-field" className="col-end-3 text-red-500">
                    {errorMsg}
                  </p>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-6xl rounded-3xl border border-blue-150 bg-gray-50 px-4 py-10 text-center lg:py-20 xl:px-14 2xl:px-20"
      >
        <div className="mx-auto grid max-w-md grid-cols-3 grid-rows-1 text-lg font-bold text-primary md:text-xl lg:max-w-5xl lg:text-2xl">
          {isSettingsEnabled && (
            <button
              onClick={() => {
                setIsSettingsEnabled(!isSettingsEnabled);
              }}
              type="button"
              className="col-start-3 col-end-3 justify-self-end"
            >
              <CloseBurgerIcon className="h-6 w-6" />
            </button>
          )}
          <button
            onClick={() => {
              setIsSettingsEnabled(!isSettingsEnabled);
            }}
            type="button"
            className="col-start-2 col-end-2 row-start-1 row-end-1 inline-flex items-center justify-self-center"
            data-test="account-settings-button"
          >
            Ρυθμίσεις
            <SettingsIcon className="ml-4 h-6 w-6 sm:ml-10" />
          </button>
        </div>
        {isSettingsEnabled && (
          <div className="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-7 rounded-3xl bg-white px-2 py-10 lg:max-w-5xl lg:grid-cols-2">
            {/* Section: Notification & Privacy Settings */}
            <div className="px-6 lg:col-span-2">
              <h2 className="border-b pb-4 text-xl font-bold text-gray-900">
                Ειδοποιήσεις και Απόρρητο
              </h2>
            </div>

            {/* Privacy Policy */}
            <div className="flex flex-col gap-5 px-6 text-left text-lg font-bold sm:text-xl">
              <span className="text-lg font-bold sm:text-xl">
                Πολιτική Απορρήτου
              </span>
              <div className="mb-5 inline-flex items-center">
                <span className="text-lg font-normal">
                  Αποδέχομαι την
                  <a
                    className="text-blue-700"
                    href="https://www.hhg.gr/el/πολιτική-προστασίας-προσωπικών-δεδομένων"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    Πολιτική απορρήτου{' '}
                  </a>
                  του ομίλου HHG.
                </span>
                <div className="relative ml-6">
                  <Switch
                    data-test="account-settings-tos-switch"
                    checked={tosEnabled}
                    disabled={true}
                  >
                    {({ checked }) => (
                      <div className="relative inline-flex h-4 w-9 items-center rounded-full bg-gray-300">
                        <span
                          className={cn(
                            'inline-block h-5 w-5 transform rounded-full transition',
                            checked
                              ? 'translate-x-4 bg-blue-500'
                              : 'translate-x-0 bg-gray-400',
                          )}
                        />
                      </div>
                    )}
                  </Switch>
                </div>
              </div>
            </div>

            {/* Email Notifications */}
            <div className="flex flex-col gap-5 px-6 text-left text-lg font-bold sm:text-xl">
              <span className="text-lg font-bold sm:text-xl">
                Ειδοποιήσεις Email
              </span>
              <div className="relative mb-5 inline-flex items-center">
                <span className="text-lg font-normal">
                  Αποδέχομαι τη λήψη ειδοποιήσεων μέσω email από τον όμιλο HHG.
                </span>
                <div className="relative ml-6">
                  <Switch
                    data-test="account-settings-notifications-switch"
                    checked={emailNotificationsEnabled}
                    disabled={true}
                  >
                    {({ checked }) => (
                      <div className="relative inline-flex h-4 w-9 items-center rounded-full bg-gray-300">
                        <span
                          className={cn(
                            'inline-block h-5 w-5 transform rounded-full transition',
                            checked
                              ? 'translate-x-4 bg-blue-500'
                              : 'translate-x-0 bg-gray-400',
                          )}
                        />
                      </div>
                    )}
                  </Switch>
                </div>
              </div>
            </div>

            {/* SMS Notifications */}
            <div className="flex flex-col gap-5 px-6 text-left text-lg font-bold sm:text-xl">
              <span className="text-lg font-bold sm:text-xl">
                Ειδοποιήσεις SMS
              </span>
              <div className="relative mb-5 inline-flex items-center">
                <span className="text-lg font-normal">
                  Αποδέχομαι τη λήψη ειδοποιήσεων μέσω SMS από τον όμιλο HHG.
                </span>
                <div className="relative ml-6">
                  <Switch
                    data-test="account-settings-sms-notifications-switch"
                    checked={smsNotificationsEnabled}
                    disabled={true}
                  >
                    {({ checked }) => (
                      <div className="relative inline-flex h-4 w-9 items-center rounded-full bg-gray-300">
                        <span
                          className={cn(
                            'inline-block h-5 w-5 transform rounded-full transition',
                            checked
                              ? 'translate-x-4 bg-blue-500'
                              : 'translate-x-0 bg-gray-400',
                          )}
                        />
                      </div>
                    )}
                  </Switch>
                </div>
              </div>
            </div>

            {/* Section: Change Password */}

            <div className="mt-6 border-t px-6 pt-6 lg:col-span-2">
              <h2 className="border-b pb-4 text-xl font-bold text-gray-900">
                Ασφάλεια
              </h2>
            </div>
            <div className="flex flex-col gap-5 px-6 text-left text-lg font-bold sm:text-xl">
              <span className="text-lg font-bold sm:text-xl">
                Αλλαγή Κωδικού
              </span>
              <div
                className="relative mb-5 inline-flex cursor-pointer items-center justify-between rounded-lg p-3 transition-all hover:bg-gray-100"
                onClick={() =>
                  setIsChangePasswordEnabled(!isChangePasswordEnabled)
                }
              >
                <div className="flex items-center gap-3">
                  <LockIcon className="h-5 w-5 text-gray-600" />{' '}
                  {/* Lock icon */}
                  <span className="text-lg font-normal text-gray-700">
                    Ενημέρωση του κωδικού πρόσβασης
                  </span>
                </div>
                <CaretRightIcon className="h-5 w-5 text-gray-600" />{' '}
                {/* Arrow icon */}
              </div>
            </div>

            {/* Change Password Form */}
            {isChangePasswordEnabled && (
              <div className="flex flex-col gap-5 px-6 text-left text-lg sm:text-xl">
                <ChangePasswordForm
                  onCancel={() => {
                    setIsChangePasswordEnabled(false);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto grid max-w-6xl grid-cols-1 gap-6 bg-white sm:grid-cols-2"
      >
        <button
          type="button"
          className="flex w-full flex-row items-center rounded-3xl border border-blue-150 bg-gray-50 py-10 text-center text-xl font-bold text-primary sm:text-3xl lg:py-20"
          onClick={() => navigate('/new/reservations')}
        >
          <div className="mx-auto inline-flex items-center">
            <span>Νέα κράτηση</span>
            <AdditionIcon className="ml-10 h-8 w-8" />
          </div>
        </button>
        <button
          type="button"
          className="flex w-full flex-row items-center rounded-3xl border border-blue-150 bg-gray-50 py-10 text-center text-xl font-bold text-primary sm:text-3xl lg:py-20"
          data-test="account-reservations-button"
          onClick={() => navigate('/appointments')}
        >
          <div className="mx-auto inline-flex items-center">
            <span>Kρατήσεις</span>
            <UserIcon className="ml-10 h-7 w-8" />
          </div>
        </button>
      </div>
    </main>
  );
}
