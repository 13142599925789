import { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { endDate, startDate } from 'utils/consts';
import useStore from 'store';
import {
  InitialUserSelectionType,
  PointsType,
  ServiceGroupType,
  ServiceType,
  ServiceTypeEnum,
} from 'types';
import validationSchema from 'utils/validationSchema';
import NoDigitalAppointment from 'components/NoDigitalAppointment';
import { ReactComponent as MagnifyingLens } from 'img/magnifying-lens.svg';
import {
  ExclamationMark,
  HomeCalendarIcon,
  HomeConfirmIcon,
  HomeDoctorIcon,
  MainHeart,
} from 'components/svgs';
import servicesIcon from 'img/services.svg';
import simpleDoctor from 'img/simple-doctor.svg';
import building from 'img/building.svg';
import callThumb from 'img/call-thump.svg';
import bubbles from 'img/smart-guide-bubles.svg';
import myYgeiaIcon from 'img/my-ygeia-icon.png';
import { Select } from '../components/Select';
import ExpiredMsg from 'components/ExpiredMsg';
import useScrollTo from 'hooks/useScrollTo';
import { getCustomizations, getServices } from 'helpers';
import { useServicesData } from 'api';
import { default as Error } from './reservation/components/ErrorMessage';
import { useAuth } from '../auth/AuthProvider';
import getToken from '../utils/getToken';
export default function Home() {
  const navigate = useNavigate();
  const [serviceGroup, setServiceGroup] = useState<ServiceGroupType>();
  const [isDigitalAppointment, setIsDigitalAppointment] = useState(true);
  const [noDigitalAppointmentServiceType, setNoDigitalAppointmentServiceType] =
    useState<ServiceTypeEnum>();
  const [pointContactInfo, setPointContactInfo] = useState<string>();

  const { isAppsExpired, setIsAppsExpired, setCurrentAppointment } = useStore();

  const { scrollToTop } = useScrollTo();
  const { token } = useAuth();
  const isLoggedIn = !!token;
  const { data, error, isFetching } = useServicesData();

  const serviceGrp: ServiceGroupType[] = data?.serviceGrp ?? [];
  const services: ServiceType[] = data?.services ?? [];
  const points: PointsType[] = data?.points ?? [];

  const handleSubmit = async (values: InitialUserSelectionType) => {
    try {
      setCurrentAppointment({
        ...values,
        start: startDate,
        end: endDate,
      });

      let customization = getCustomizations(values?.service, values?.point);
      const isServiceOnline =
        customization !== undefined
          ? customization?.service_type === ServiceTypeEnum.CALL_N_ONLINE
          : values.service.service_type === ServiceTypeEnum.CALL_N_ONLINE;

      if (!isServiceOnline) {
        setIsDigitalAppointment(false);
        setNoDigitalAppointmentServiceType(
          customization !== undefined
            ? customization.service_type
            : values.service.service_type,
        );
      } else {
        navigate('new/reservations');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMyYgeiaLogin = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault(); // Prevent default navigation behavior

    let redirectURL = 'https://my-ygeia.gr';
    try {
      let ldap = getToken('ldap');
      let email = localStorage.getItem('email');
      if (ldap) {
        // https://my-ygeia.gr?username=user&access_token=token&id_token=id
        // @ts-ignore
        redirectURL = `https://my-ygeia.gr?username=${email}&access_token=${ldap['access_token']}&id_token=${ldap['id_token']}`;
      }
    } catch (error) {
      console.error('Error connecting to My Ygeia auth:', error);
    }

    window.location.href = redirectURL;
  };

  useEffect(() => {
    scrollToTop();
  });

  if (isFetching) {
    return (
      <div className="wrapper">
        <div className="cs-page-loading-inner">
          <div className="cs-page-spinner">
            <div></div>
          </div>
          <p>Help - Βοήθεια για εσένα...</p>
        </div>
        {!!error && (
          <div className="mt-16">
            <Error message="Υπήρξε πρόβλημα με την ανάκτηση των δεδομένων. Παρακαλούμε δοκιμάστε αργότερα. " />
          </div>
        )}
      </div>
    );
  }

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <Formik
          initialValues={
            {
              serviceGrp: {},
              service: {},
              point: {},
            } as InitialUserSelectionType
          }
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <h1 className="mb-6 text-2xl font-bold md:mb-10 md:text-3xl lg:text-4xl">
                  Κλείστε το ραντεβού σας εύκολα και γρήγορα διαδικτυακά
                </h1>

                <div className="lg:grid-rows-0 mx-auto grid max-w-md grid-rows-1 lg:max-w-full lg:grid-cols-3 lg:space-x-5">
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    aria-label="Select Service"
                    className="relative mt-4 rounded-md shadow-sm sm:mt-6"
                  >
                    <Field
                      as={Select}
                      title="Ομάδα υπηρεσιών"
                      name="serviceGrp"
                      serviceGrp={serviceGrp}
                      servicesIcon={servicesIcon}
                      handleOnChange={(item: ServiceGroupType) => {
                        setFieldValue('serviceGrp', item);
                        setFieldValue('service', '');
                        setFieldValue('point', '');
                        setServiceGroup(item);
                        setIsAppsExpired(false);
                        setIsDigitalAppointment(true);
                      }}
                      data-test="select-serviceGrp"
                    />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    className="relative mt-4 rounded-md shadow-sm sm:mt-6"
                    aria-label="Select Service"
                  >
                    <Field
                      as={Select}
                      title="Υπηρεσία"
                      name="service"
                      serviceGrp={getServices(services, serviceGroup)}
                      servicesIcon={simpleDoctor}
                      handleOnChange={(item: ServiceType) => {
                        setFieldValue('service', item);
                        setIsDigitalAppointment(true);
                      }}
                      data-test="select-service"
                    />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="600"
                    data-aos-duration="1000"
                    className="relative mt-4 rounded-md shadow-sm sm:mt-6"
                    aria-label="Select Service"
                  >
                    <Field
                      as={Select}
                      title="Σημείο εξυπηρέτησης"
                      name="point"
                      serviceGrp={points}
                      servicesIcon={building}
                      handleOnChange={(item: PointsType) => {
                        setFieldValue('point', item);
                        setPointContactInfo(item.contact_information);
                        setIsDigitalAppointment(true);
                      }}
                      data-test="select-point"
                    />
                  </div>
                </div>

                <ErrorMessage
                  name="serviceGrp"
                  render={(msg) => (
                    <div className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl">
                      <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
                        <ExclamationMark />
                      </div>
                      <p className="text-left text-xs md:text-base">{msg}</p>
                    </div>
                  )}
                />
                <ErrorMessage
                  name="service"
                  render={(msg) => (
                    <div className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl">
                      <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
                        <ExclamationMark />
                      </div>
                      <p className="text-left text-xs md:text-base">{msg}</p>
                    </div>
                  )}
                />
                <ErrorMessage
                  name="point"
                  render={(msg) => (
                    <div className="mx-auto my-6 flex max-w-md flex-row items-center justify-center space-x-4 rounded-lg bg-red-100 p-6 md:max-w-3xl">
                      <div className="h-8 w-8 flex-shrink-0 text-red-600 md:h-9 md:w-9">
                        <ExclamationMark />
                      </div>
                      <p className="text-left text-xs md:text-base">{msg}</p>
                    </div>
                  )}
                />

                {!isDigitalAppointment && (
                  <NoDigitalAppointment
                    pointContactInformation={pointContactInfo as string}
                    noDigitalAppointmentServiceType={
                      noDigitalAppointmentServiceType as ServiceTypeEnum
                    }
                  />
                )}

                <div
                  data-aos="zoom-in"
                  data-aos-once="true"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                  className="mt-6 flex w-full flex-row justify-center lg:justify-end"
                >
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="inline-flex cursor-pointer items-center rounded-full border-2 border-primary bg-primary px-6 py-3.5 text-center text-lg font-semibold text-white transition-all hover:bg-gray-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
                    data-test="submit-appointment-services"
                  >
                    {isSubmitting ? 'Αναζήτηση' : 'Αναζήτηση'}
                    <MagnifyingLens className="ml-2.5 h-6 w-6" />
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      {isAppsExpired && <ExpiredMsg />}

      <div
        data-aos="fade-in"
        data-aos-once="true"
        data-aos-delay="100"
        data-aos-duration="1300"
        className={`mb-10 ${
          isLoggedIn
            ? 'grid grid-cols-3 gap-4 md:gap-5 lg:mb-20' // Logged in: 3 columns
            : 'grid grid-rows-2 space-y-4 sm:grid-cols-2 sm:grid-rows-1 sm:space-x-4 sm:space-y-0 md:space-x-5 lg:mb-20' // Logged out: 2 rows
        }`}
      >
        <a
          href="tools/callback"
          className="flex h-full rounded-3xl border border-gray-200 bg-gray-50 px-6 py-10 transition-all hover:border-blue-600 hover:bg-blue-100"
        >
          <div className="mx-auto flex w-full items-center justify-center">
            <img
              src={callThumb}
              alt="a thumb up near a phone"
              className="h-14 w-14 flex-shrink-0 lg:h-20 lg:w-20"
            />
            <div className="ml-6 xl:ml-16">
              <h3 className="mb-4 text-xl font-bold xl:mb-1 xl:text-2xl">
                Σε καλούμε δωρεάν
              </h3>
              <p className="text-base xl:text-lg">
                Δώσε μας το τηλέφωνό σου και θα σε καλέσουμε εμείς.
              </p>
            </div>
          </div>
        </a>

        <a
          href="/faq"
          className="flex h-full rounded-3xl border border-gray-200 bg-gray-50 px-6 py-10 transition-all hover:border-blue-600 hover:bg-blue-100"
        >
          <div className="mx-auto flex w-full items-center justify-center">
            <img
              src={bubbles}
              alt="a set of chat bubbles"
              className="h-14 w-14 flex-shrink-0 lg:h-20 lg:w-20"
            />
            <div className="ml-6 xl:ml-16">
              <h3 className="mb-4 text-xl font-bold xl:mb-1 xl:text-2xl">
                Συχνές Ερωτήσεις
              </h3>
              <p className="text-base xl:text-lg">
                Μάθετε για το πως θα κλείσετε ραντεβού εύκολα και ψηφιακά.
              </p>
            </div>
          </div>
        </a>

        {isLoggedIn && (
          <button
            onClick={handleMyYgeiaLogin}
            className="flex h-full w-full rounded-3xl border border-gray-200 bg-gray-50 px-6 py-10 text-left transition-all hover:border-blue-600 hover:bg-blue-100"
          >
            <div className="mx-auto flex w-full items-center justify-center">
              <img
                src={myYgeiaIcon}
                alt="my ygeia logo"
                className="h-14 w-14 flex-shrink-0 lg:h-20 lg:w-20"
              />
              <div className="ml-6 xl:ml-16">
                <h3 className="mb-4 text-xl font-bold xl:mb-1 xl:text-2xl">
                  Σύνδεση στο my ygeia
                </h3>
                <p className="text-base xl:text-lg">
                  Συνδεθείτε άμεσα στην εφαρμογή my ygeia με ένα κλικ.
                </p>
              </div>
            </div>
          </button>
        )}
      </div>

      <div
        data-aos="fade-in"
        data-aos-once="true"
        data-aos-delay="300"
        data-aos-duration="1500"
        className="rounded-3xl bg-gradient-to-br from-blue-200 to-gray-50 pb-10 pt-10 text-center lg:pb-20 xl:pb-28"
      >
        <h2 className="mb-10 text-2xl font-bold lg:mb-16 xl:text-3xl">
          Η υγεία σου σε τρία απλά βήματα
        </h2>
        <div className="mx-auto flex w-full flex-col items-center justify-center px-4 md:space-x-6 lg:flex-row lg:space-x-10 xl:space-x-8 2xl:space-x-28">
          <div className="mb-10 space-y-4 md:space-y-8 lg:mb-0">
            <div className="relative mx-auto flex max-w-md flex-row items-center justify-center rounded-2xl p-6 text-left lg:max-w-lg">
              <HomeDoctorIcon className="relative z-10 mr-6 h-12 w-12 flex-shrink-0 md:h-20 md:w-20" />
              <p className="relative z-10 text-base text-black md:text-lg">
                Επέλεξε την υπηρεσία και την περιοχή που σε ενδιαφέρει
              </p>

              <div className="absolute inset-0 z-0 rounded-2xl bg-gray-50 opacity-40"></div>
            </div>
            <div className="relative mx-auto flex max-w-md flex-row items-center justify-center rounded-2xl p-6 text-left lg:max-w-lg">
              <HomeCalendarIcon className="relative z-10 mr-6 h-12 w-12 flex-shrink-0 md:h-20 md:w-20" />
              <p className="relative z-10 text-base text-black md:text-lg">
                Επέλεξε την ημέρα και ώρα που σε εξυπηρετεί
              </p>

              <div className="absolute inset-0 z-0 rounded-2xl bg-gray-50 opacity-40"></div>
            </div>
            <div className="relative mx-auto flex max-w-md flex-row items-center justify-center rounded-2xl p-6 text-left lg:max-w-lg">
              <HomeConfirmIcon className="relative z-10 mr-6 h-12 w-12 flex-shrink-0 md:h-20 md:w-20" />
              <p className="relative z-10 text-base text-black md:text-lg">
                Επιβεβαίωσε το ραντεβού εύκολα και δωρέαν
              </p>

              <div className="absolute inset-0 z-0 rounded-2xl bg-gray-50 opacity-40"></div>
            </div>
          </div>

          <div className="flex max-w-sm lg:max-w-md xl:max-w-xl">
            <MainHeart className="w-full" />
          </div>
        </div>
      </div>
    </main>
  );
}
