import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import request from 'request';
import { useAuth } from 'auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Loading } from './Loader';
import { NotVisibleIcon, VisibleIcon } from './svgs';

interface Props {
  onCancel: () => void;
}

interface ChangePasswordFormValues {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

const ChangePasswordForm = ({ onCancel }: Props) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOldPassVisible, setOldIsPassVisible] = useState(false);
  const [isNewPassVisible, setNewIsPassVisible] = useState(false);
  const [isConfirmPassVisible, setConfirmIsPassVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Εισάγετε τον τρέχων κωδικό'),

    new_password: Yup.string()
      .min(10, 'Ο κωδικός πρέπει να έχει τουλάχιστον 10 χαρακτήρες')
      .matches(
        /[A-Z]/,
        'Ο κωδικός πρέπει να περιέχει τουλάχιστον ένα κεφαλαίο γράμμα',
      )
      .matches(/\d/, 'Ο κωδικός πρέπει να περιέχει τουλάχιστον έναν αριθμό')
      .required('Εισάγετε νέο κωδικό'),

    confirm_password: Yup.string()
      .oneOf([Yup.ref('new_password')], 'Οι κωδικοί δεν ταιριάζουν')
      .required('Επιβεβαιώστε τον νέο κωδικό'),
  });

  const handleChangePassword = async (
    values: ChangePasswordFormValues,
    { setErrors }: FormikHelpers<any>,
  ) => {
    setErrorMessage('');
    setIsSubmitting(true);
    try {
      const response = await request.post('/api/v1/auth/change-password/', {
        old_password: values.old_password,
        new_password: values.new_password,
      });

      if (response.status === 200) {
        alert('Ο κωδικός σας άλλαξε επιτυχώς! Παρακαλώ συνδεθείτε ξανά.');
        logout(); // Force logout for security
        navigate('/login');
      }
    } catch (error: any) {
      const { code, errors } = error.response.data;

      if (code === 'invalid_credentials') {
        setErrors({ old_password: 'Ο τρέχων κωδικός είναι λάθος' });
      } else if (errors?.new_password) {
        setErrors({ new_password: errors.new_password });
      } else {
        setErrorMessage(
          error.response?.data?.message ||
            'Ουπς! Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε αργότερα.',
        );
      }
    }
    setIsSubmitting(false);
  };

  const handleVisible = (field: string) => {
    switch (field) {
      case 'old_password':
        setOldIsPassVisible(!isOldPassVisible);
        break;
      case 'new_password':
        setNewIsPassVisible(!isNewPassVisible);
        break;
      case 'confirm_password':
        setConfirmIsPassVisible(!isConfirmPassVisible);
        break;
    }
  };

  return (
    <div className="mx-auto w-full max-w-md">
      <Formik
        initialValues={{
          old_password: '',
          new_password: '',
          confirm_password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleChangePassword}
      >
        <Form className="mt-4">
          <div className="mx-auto max-w-md text-left">
            <div className="relative mt-6">
              <label
                htmlFor="old_password"
                className="block text-sm font-medium text-gray-700"
              >
                Τρέχων Κωδικός
              </label>
              <Field
                type={isOldPassVisible ? 'text' : 'password'}
                name="old_password"
                id="old_password"
                className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
              />
              <div className="absolute inset-y-0 right-4 top-7 flex items-center pr-3 text-sm leading-5">
                <button
                  onClick={() => handleVisible('old_password')}
                  type="button"
                  className="h-8 w-8 text-primary hover:text-blue-900"
                >
                  {!isOldPassVisible ? <VisibleIcon /> : <NotVisibleIcon />}
                </button>
              </div>
            </div>
            <ErrorMessage
              name="old_password"
              component="div"
              className="mt-2 text-xs text-red-600"
              data-test="account-old_password-error"
            />
            <div className="relative mt-6">
              <label
                htmlFor="new_password"
                className="block text-sm font-medium text-gray-700"
              >
                Νέος Κωδικός
              </label>
              <Field
                type={isNewPassVisible ? 'text' : 'password'}
                name="new_password"
                id="new_password"
                className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
              />
              <div className="absolute inset-y-0 right-4 top-7 flex items-center pr-3 text-sm leading-5">
                <button
                  onClick={() => handleVisible('new_password')}
                  type="button"
                  className="h-8 w-8 text-primary hover:text-blue-900"
                >
                  {!isNewPassVisible ? <VisibleIcon /> : <NotVisibleIcon />}
                </button>
              </div>
            </div>
            <ErrorMessage
              name="new_password"
              component="div"
              className="mt-2 min-h-[1rem] text-xs text-red-600"
              data-test="account-new_password-error"
            />
            <div className="relative mt-6">
              <label
                htmlFor="confirm_password"
                className="block text-sm font-medium text-gray-700"
              >
                Επιβεβαίωση Νέου Κωδικού
              </label>
              <Field
                type={isConfirmPassVisible ? 'text' : 'password'}
                name="confirm_password"
                id="confirm_password"
                className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
              />
              <div className="absolute inset-y-0 right-4 top-7 flex items-center pr-3 text-sm leading-5">
                <button
                  onClick={() => handleVisible('confirm_password')}
                  type="button"
                  className="h-8 w-8 text-primary hover:text-blue-900"
                >
                  {!isConfirmPassVisible ? <VisibleIcon /> : <NotVisibleIcon />}
                </button>
              </div>
            </div>
            <ErrorMessage
              name="confirm_password"
              component="div"
              className="mt-2 text-xs text-red-600"
              data-test="account-confirm_password-error"
            />
            <div className="relative mt-6 flex gap-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex w-full items-center justify-center rounded-full border border-primary bg-primary px-4 py-2 text-center text-base font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                <span className="flex items-center justify-between gap-2">
                  Αποθήκευση {isSubmitting && <Loading size={15}></Loading>}
                </span>
              </button>
              <button
                disabled={isSubmitting}
                className="inline-flex w-full items-center justify-center rounded-full border border-primary bg-gray-50 px-4 py-2 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
                onClick={onCancel}
              >
                Άκυρο
              </button>
            </div>
          </div>
        </Form>
      </Formik>
      {errorMessage && (
        <p className="mt-2 text-base text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default ChangePasswordForm;
