import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import cn from 'classnames';

import { ConsentPopupProps } from '../types';

const UserInfoSyncConsentPopup: React.FC<ConsentPopupProps> = ({
  onConfirm,
  onCancel,
}) => {
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] =
    useState(false);
  const [smsNotificationsEnabled, setSmsNotificationsEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isSyncButtonEnabled =
    acceptedPrivacyPolicy &&
    emailNotificationsEnabled &&
    smsNotificationsEnabled;

  const handleConfirm = async () => {
    setErrorMessage(null); // Clear previous errors
    setIsSubmitting(true);

    try {
      await onConfirm(
        acceptedPrivacyPolicy,
        emailNotificationsEnabled,
        smsNotificationsEnabled,
      );
    } catch (error: any) {
      // Handle error from `onConfirm` and display it
      setErrorMessage(
        error.message || 'Αποτυχία συγχρονισμού. Προσπαθήστε ξανά.',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-11/12 max-w-md rounded-lg bg-white p-6 shadow-lg">
        <h2 className="text-xl font-semibold text-gray-800">
          Συγχρονισμός του Λογαριασμού σας
        </h2>
        <p className="mt-4 text-gray-600">
          Φαίνεται ότι έχετε ήδη λογαριασμό στην εφαρμογή MyYgeia. Θα θέλατε να
          συγχρονίσετε τον λογαριασμό σας με αυτή την εφαρμογή Κρατήσεων;
        </p>

        {/* Privacy Policy */}
        <div className="mt-6 flex items-center justify-between">
          <span className="mr-4 flex-1 text-gray-700">
            Αποδέχομαι την
            <a
              className="text-blue-700"
              href="https://www.hhg.gr/el/πολιτική-προστασίας-προσωπικών-δεδομένων"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Πολιτική απορρήτου{' '}
            </a>
            του ομίλου HHG.
          </span>
          <Switch
            checked={acceptedPrivacyPolicy}
            onChange={setAcceptedPrivacyPolicy}
            className={cn(
              'relative inline-flex h-6 w-12 items-center rounded-full transition-all',
              acceptedPrivacyPolicy ? 'bg-blue-500' : 'bg-gray-300',
            )}
          >
            <span
              className={cn(
                'inline-block h-6 w-6 transform rounded-full bg-white transition-all',
                acceptedPrivacyPolicy ? 'translate-x-6' : 'translate-x-0',
              )}
            />
          </Switch>
        </div>

        {/* Email Notifications */}
        <div className="mt-6 flex items-center justify-between">
          <span className="mr-4 flex-1 text-gray-700">
            Αποδέχομαι τη λήψη ειδοποιήσεων μέσω email από τον όμιλο HHG.
          </span>
          <Switch
            checked={emailNotificationsEnabled}
            onChange={setEmailNotificationsEnabled}
            className={cn(
              'relative inline-flex h-6 w-12 items-center rounded-full transition-all',
              emailNotificationsEnabled ? 'bg-blue-500' : 'bg-gray-300',
            )}
          >
            <span
              className={cn(
                'inline-block h-6 w-6 transform rounded-full bg-white transition-all',
                emailNotificationsEnabled ? 'translate-x-6' : 'translate-x-0',
              )}
            />
          </Switch>
        </div>

        {/* SMS Notifications */}
        <div className="mt-6 flex items-center justify-between">
          <span className="mr-4 flex-1 text-gray-700">
            Αποδέχομαι τη λήψη ειδοποιήσεων μέσω SMS από τον όμιλο HHG.
          </span>
          <Switch
            checked={smsNotificationsEnabled}
            onChange={setSmsNotificationsEnabled}
            className={cn(
              'relative inline-flex h-6 w-12 items-center rounded-full transition-all',
              smsNotificationsEnabled ? 'bg-blue-500' : 'bg-gray-300',
            )}
          >
            <span
              className={cn(
                'inline-block h-6 w-6 transform rounded-full bg-white transition-all',
                smsNotificationsEnabled ? 'translate-x-6' : 'translate-x-0',
              )}
            />
          </Switch>
        </div>

        {/* Error Message */}
        {errorMessage && (
          <div className="mt-4 rounded bg-red-100 p-3 text-sm text-red-700">
            {errorMessage}
          </div>
        )}

        {/* Action Buttons */}
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="rounded-lg bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300 focus:outline-none"
            disabled={isSubmitting}
          >
            Ακύρωση
          </button>
          <button
            onClick={handleConfirm}
            disabled={!isSyncButtonEnabled || isSubmitting}
            className={`rounded-lg px-4 py-2 text-white transition-all focus:outline-none ${
              isSyncButtonEnabled
                ? 'bg-blue-600 hover:bg-blue-700'
                : 'cursor-not-allowed bg-gray-300'
            }`}
          >
            {isSubmitting ? (
              <div className="flex items-center space-x-2">
                <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                <span>Παρακαλώ περιμένετε...</span>
              </div>
            ) : (
              'Ναι, Συγχρονισμός'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoSyncConsentPopup;
