import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import {
  NextStepIcon,
  NotVisibleIcon,
  VisibleIcon,
  TickIcon,
} from 'components/svgs';

interface NewPasswordField {
  password: string;
  password_confirmation: string;
}

export default function Recover() {
  const navigate = useNavigate();
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isPassChanged, setIsPassChanged] = useState(false);
  const [hasError, setHasError] = useState(false);

  const params = new URLSearchParams(window.location.search);

  if (!params.get('token') || !params.get('id')) navigate('/error404');

  const handleSubmit = async (values: NewPasswordField) => {
    try {
      const body = {
        token: params.get('token') ?? '',
        uidb64: params.get('id') ?? '',
        password: values.password,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/reset-password-confirm/`,
        body,
      );
      if (response.status === 200) {
        setIsPassChanged(true);
        return;
      }

      setHasError(true);
    } catch (error) {
      setHasError(true);
    }
  };

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(10, 'Ο κωδικός πρέπει να έχει τουλάχιστον 10 χαρακτήρες')
      .matches(
        /[A-Z]/,
        'Ο κωδικός πρέπει να περιέχει τουλάχιστον ένα κεφαλαίο γράμμα',
      )
      .matches(/\d/, 'Ο κωδικός πρέπει να περιέχει τουλάχιστον έναν αριθμό')
      .required('Εισάγετε νέο κωδικό'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Οι κωδικοί δεν ταιριάζουν')
      .required('Εισάγετε ξανά τον νέο κωδικό σας'),
  });

  const handleVisible = () => {
    setIsPassVisible(!isPassVisible);
  };

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      {!isPassChanged ? (
        <>
          <div
            data-aos="zoom-in"
            data-aos-once="true"
            data-aos-duration="1000"
            className="mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center xl:px-14 2xl:px-20"
          >
            <h1 className="mb-6 text-2xl font-bold md:text-3xl lg:text-4xl">
              Εισάγετε το νέο σας κωδικό
            </h1>
            <Formik
              initialValues={{
                password: '',
                password_confirmation: '',
              }}
              validationSchema={PasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="mx-auto max-w-md text-left">
                  <div className="relative mt-6">
                    <label
                      htmlFor="password"
                      className="mb-1.5 block text-sm text-gray-900 md:text-base"
                    >
                      ΝΕΟΣ ΚΩΔΙΚΟΣ
                    </label>
                    <Field
                      type={isPassVisible ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                      data-test="recover-password-field"
                    />

                    <div className="absolute inset-y-0 right-4 top-7 flex items-center pr-3 text-sm leading-5">
                      <button
                        onClick={handleVisible}
                        type="button"
                        className="h-8 w-8 text-primary hover:text-blue-900"
                      >
                        {!isPassVisible ? <VisibleIcon /> : <NotVisibleIcon />}
                      </button>
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="recover-password-error"
                  />
                  <div className="relative mt-6">
                    <label
                      htmlFor="password"
                      className="mb-1.5 block text-sm text-gray-900 md:text-base"
                    >
                      ΕΠΑΛΗΘΕΥΣΗ ΚΩΔΙΚΟΥ
                    </label>
                    <Field
                      type={isPassVisible ? 'text' : 'password'}
                      id="password_confirmation"
                      name="password_confirmation"
                      className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                      data-test="recover-password-confirmation-field"
                    />

                    <div className="absolute inset-y-0 right-4 top-7 flex items-center pr-3 text-sm leading-5">
                      <button
                        onClick={handleVisible}
                        type="button"
                        className="h-8 w-8 text-primary hover:text-blue-900"
                      >
                        {!isPassVisible ? <VisibleIcon /> : <NotVisibleIcon />}
                      </button>
                    </div>
                  </div>
                  <ErrorMessage
                    name="password_confirmation"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="recover-password-confirmation-error"
                  />
                  <div className="mt-6 w-full text-center">
                    <button
                      type="submit"
                      data-test="recover-submit-button"
                      disabled={isSubmitting}
                      className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                    >
                      {isSubmitting ? 'Αναμονη...' : 'Υποβολή'}
                      <NextStepIcon className="ml-2.5 h-6 w-6" />
                    </button>
                    {hasError && (
                      <div className="mt-8 text-center text-xs text-red-600">
                        Ουπς! Κάτι πήγε στραβα. Παρακαλώ προσπαθήστε αργότερα.
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <>
          <div className="mx-auto mb-10 flex flex-col items-center rounded-3xl bg-gray-50 px-4 py-10 text-center sm:max-w-2xl xl:px-14 2xl:px-20">
            <h1 className="mb-6 text-2xl font-bold md:text-3xl lg:text-4xl">
              Ο κωδικός σας άλλαξε!
            </h1>
            <p className="mb-6 max-w-lg text-base sm:text-xl">
              Μεταβείτε στη σελίδα σύνδεσης για να εισέλθετε στην εφαρμογή με το
              νέο σας κωδικό.
            </p>
            <div className="mb-6 text-green-600">
              <TickIcon className="h-14 w-14" />
            </div>
            <div className=" w-full text-center">
              <button
                onClick={() => {
                  navigate('/login');
                }}
                data-test="recover-login-button"
                className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
              >
                <span>Συνδεθείτε</span>
                <NextStepIcon className="ml-2.5 h-6 w-6" />
              </button>
            </div>
          </div>
        </>
      )}
    </main>
  );
}
