import { useState } from 'react';
import { ErrorMessage, Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useAuth } from 'auth/AuthProvider';
import { LoginFields } from 'types';
import request from 'request';

import { NextStepIcon, NotVisibleIcon, VisibleIcon } from 'components/svgs';
import axios from 'axios';

import UserInfoSyncConsentPopup from './UserInfoSyncConsentPopup';

import { LDAPToken } from '../types';
import InActiveUser from '../components/InActiveUser';

export default function Login() {
  const [isVisible, setIsVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userIsConfirmed, setUserIsConfirmed] = useState(true);
  const [email, setEmail] = useState<string>('');
  const { login } = useAuth();
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [tempToken, setTempToken] = useState<LDAPToken | null>(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Εισάγετε μια έγκυρη διεύθυνση email')
      .required('Εισάγετε το email σας'),
    password: Yup.string()
      .required('Εισάγετε τον κωδικό σας')
      .min(10, 'Χρησιμοποιήστε τουλάχιστον 10 ψηφία')
      .required('Εισάγετε τον κωδικό σας'),
  });

  const handleSubmit = async (
    values: LoginFields,
    { setErrors }: FormikHelpers<any>,
  ) => {
    // Reset the inactive user state at the beginning of each attempt
    setUserIsConfirmed(true);
    setErrorMsg('');
    try {
      const { data } = await request.post('/api/v1/auth/internal/token/', {
        email: values.email,
        password: values.password,
      });
      if (data?.access) {
        setUserIsConfirmed(true);
        login(data.access, data.ldap, values.email); // Handle successful login
      }
    } catch (error: any) {
      console.error(error);

      if (axios.isAxiosError(error) && error.response) {
        const { code, temp_auth } = error.response.data;
        switch (code) {
          case 'user_not_found_in_db':
            if (temp_auth) {
              setTempToken(temp_auth);
              setEmail(values.email);
              setShowConsentPopup(true);
            }
            break;
          case 'user_is_inactive':
            setUserIsConfirmed(false);
            setEmail(values.email);
            break;

          case 'user_not_authenticated':
            setErrors({
              email: 'Λάθος email',
              password: 'Λάθος κωδικός',
            });
            break;

          default:
            setErrorMsg(
              'Ούπς! Κάτι πήγε στραβά! Παρακαλώ προσπαθήστε αργότερα!',
            );
        }
      }
    }
  };

  const handleConsent = async () => {
    try {
      if (!tempToken || !email) {
        console.error('Missing necessary parameters for consent.');
        return;
      }

      const { access_token: accessToken, id_token: idToken } = tempToken;

      if (!accessToken || !idToken || !email) {
        console.error('Missing token or email parameters.');
        return;
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/get-info/`,
        {
          email,
          jwt_token: idToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (data) {
        login(data.access, tempToken, email); // Handle successful login or data sync
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response);
        throw new Error(
          error.response.data.detail ||
            'Αποτυχία συγχρονισμού. Προσπαθήστε ξανά.',
        );
      } else {
        throw new Error('Unexpected error occurred during synchronization.');
      }
    }
  };

  const handleVisible = () => {
    setIsVisible(!isVisible);
  };

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      {/* UserInfoSyncConsentPopup Component */}
      {showConsentPopup && (
        <UserInfoSyncConsentPopup
          onConfirm={handleConsent}
          onCancel={() => setShowConsentPopup(false)}
        />
      )}
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mx-auto mb-10 max-w-2xl rounded-3xl bg-gray-50 px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
          Συνδεθείτε
        </h1>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="mx-auto max-w-md text-left">
              <div className="mb-6 mt-10 text-left">
                <div>
                  <label
                    htmlFor="email"
                    className="mb-1.5 block text-sm text-gray-900 md:text-base"
                  >
                    EMAIL
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                    data-test="login-email-field"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="mt-2 text-xs text-red-600"
                    data-test="login-email-error"
                  />
                </div>
                <div className="relative mt-6">
                  <label
                    htmlFor="password"
                    className="mb-1.5 block text-sm text-gray-900 md:text-base"
                  >
                    ΚΩΔΙΚΟΣ
                  </label>
                  <Field
                    type={isVisible ? 'text' : 'password'}
                    id="password"
                    name="password"
                    className="block w-full rounded-full border border-gray-400 bg-white p-4 text-lg text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400 md:text-xl"
                    data-test="login-password-field"
                  />

                  <div className="absolute inset-y-0 right-4 top-7 flex items-center pr-3 text-sm leading-5">
                    <button
                      onClick={handleVisible}
                      type="button"
                      className="h-8 w-8 text-primary hover:text-blue-900"
                    >
                      {!isVisible ? <VisibleIcon /> : <NotVisibleIcon />}
                    </button>
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="mt-2 text-xs text-red-600"
                  data-test="login-password-error"
                />
              </div>
              {!userIsConfirmed && <InActiveUser email={email} />}
              <div className="flex w-full flex-row flex-wrap items-center justify-center text-lg md:text-xl">
                <p className="text-center">
                  Δεν έχετε λογαριασμό; Εγγραφείτε&nbsp;{' '}
                  <a
                    data-test="register-redirect"
                    href="/register/"
                    className="font-bold text-primary hover:text-gray-600"
                  >
                    εδώ
                  </a>
                </p>
              </div>
              <a
                href="/newpassword/"
                className="mx-auto my-6 block w-full text-center text-lg  font-bold text-primary hover:text-gray-600 md:text-xl"
              >
                Ξεχάσατε τον κωδικό σας;
              </a>
              <div className="w-full text-center">
                <button
                  type="submit"
                  data-test="login-submit-button"
                  className="inline-flex items-center rounded-full border border-primary bg-primary px-6  py-3.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400"
                >
                  {isSubmitting ? 'Είσοδος' : 'Είσοδος'}
                  <NextStepIcon className="ml-2.5 h-6 w-6" />
                </button>
                {errorMsg && (
                  <p
                    data-test="error-field"
                    className="mx-auto w-full max-w-screen-2xl px-4 py-7 text-center text-red-500 md:py-8 lg:px-10"
                  >
                    {errorMsg}
                  </p>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
}
