import EmailErrorMessage from '../views/reservation/components/ErrorMessage';
import SuccesfulEmailResentMessage from '../views/reservation/components/SuccefulEmailResentMessage';
import axios from 'axios';
import { resetSuccessfulMessage } from '../helpers';
import { useState } from 'react';

export default function InActiveUser({ email }: { email: string }) {
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailResend = async () => {
    try {
      if (emailSent) return;
      const { status } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/auth/send-verification-email/`,
        { email: email },
      );

      if (status === 200) {
        setEmailSent((prev) => !prev);
        setErrorMessage('');
        resetSuccessfulMessage(setEmailSent);
      } else
        setErrorMessage('Υπήρξε κάποιο πρόβλημα με την αποστολή του email.');
    } catch (error) {
      console.log(error);
      setErrorMessage('Υπήρξε κάποιο πρόβλημα με την αποστολή του email.');
    }
  };

  return (
    <div className="flex flex-col justify-center pb-7 text-center text-red-600">
      <p className="mb-3 text-xl font-semibold">
        Δεν έχετε ενεργοποιήσει τον λογαριασμό σας!
      </p>
      <p className="text-lg">
        Ελέγξτε το email σας και ακολουθείστε τις οδηγίες για την ενεργοποίηση
        του λογαριασμού σας.
      </p>
      <p className="mb-5 text-black">
        Δεν βρίσκεται το email? Για επαναποστολή πατήστε&nbsp;
        <button
          type="button"
          className="font-bold text-primary hover:text-gray-600"
          onClick={handleEmailResend}
        >
          εδώ
        </button>
      </p>
      {errorMessage ? (
        <p className="text-black">
          <EmailErrorMessage message={errorMessage} />
        </p>
      ) : (
        emailSent && (
          <p className="text-black">
            <SuccesfulEmailResentMessage />
          </p>
        )
      )}
    </div>
  );
}
